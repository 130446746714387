//using expose loader in wepack.base.config
import "expose-loader?jQuery!../lib/jquery/dist/jquery.js";
import "expose-loader?$!../lib/jquery/dist/jquery.js";
// import "../lib/bootstrap-sass/assets/javascripts/bootstrap.js";
// Manually upgraded to 3.4.1 to address security vulnerability
import "./bootstrap.js";

//jquery validate doesn't work in webpack. using cdn in /areas/Portal/Views/Shared/Scripts/Base.cshtml
//import "../lib/jquery-validation/dist/jquery.validate.js";
//import "../lib/jquery-validation-unobtrusive/jquery.validate.unobtrusive.js";
import "../lib/hammerjs/hammer.js";
import "../lib/bootstrap-material-design/dist/js/material.js";
import "../lib/bootstrap-material-design/dist/js/ripples.js";

import "./common/analytics.js";
import "./common/jquery.validator.defaults.js";
import "./common/site.config.js";
import "./phoneFormatter/phoneFormat.js";

import * as d3 from "d3";
import "d3-selection-multi";
import c3 from "c3";

window.d3 = d3;
window.c3 = c3;