(function (analytics, $) {
    var baseUrl = "/portal/analytics/";


    // Action is Click by Default, and Value is 1 by default
    analytics.submitAnalyticsEvent = function (eventCategory, eventAction, eventLabel, eventValue) {
        console.log('Submit Analytics event: ' + eventLabel)
        eventAction = (eventAction !== undefined && eventAction !== '' ? eventAction : 'click')
        eventValue = (eventValue !== undefined && eventValue !== '' ? eventValue : 1)

        // Sitecore Event
        $.post(baseUrl + "create", {
            Action: eventLabel, // Action is used to determine what goal to select (swapped label + action)
            RelativeUrl: window.location.pathname,
            Label: eventLabel,
            Text: eventLabel,
            Message: eventCategory,
            Data: eventAction, //'click'
            NonInteraction: false
        })
        .done()
        .fail(function (ex) {
            console.error(ex);
        });
        eventCategory = (eventCategory !== undefined && eventCategory !== '' ? eventCategory : ' ')

        // GTM 
        var dataLayer = window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': "interaction",
            'target': eventCategory,
            'target-properties': eventLabel,
            'action': eventAction,
            'value': eventValue,
            'interaction-type': false
        });

    }

    // How to use the tracker
    // Add the following to the Button/A element you want to push a click event for
    //class="ga-event"
    //data-event-category=""
    //data-event-label="the label to send to GA"


    $(window).bind("load", function () {

        //GA Event Tracker Script. Licensed under MIT. Free for any use by all. Written by Paul Seal from codeshare.co.uk
        // https://codeshare.co.uk/blog/how-to-set-up-ga-event-tracking-in-3-easy-steps/
        // Get the category, action and label from the element and send it to GA. The action is optional, because mostly it will be a click event.
        var trackClickEvent = function () {
            var eventCategory = this.getAttribute("data-event-category");
            var eventAction = this.getAttribute("data-event-action");
            var eventLabel = this.getAttribute("data-event-label");
            var eventValue = this.getAttribute("data-event-value");
            analytics.submitAnalyticsEvent(eventCategory, eventAction, eventLabel, eventValue)
        };

        // Find all of the elements on the page which have the class 'ga-event'
        var elementsToTrack = document.getElementsByClassName("ga-event");
        // Add an event listener to each of the elements you found
        var elementsToTrackLength = elementsToTrack.length;
        for (var i = 0; i < elementsToTrackLength; i++) {
            elementsToTrack[i].addEventListener('click', trackClickEvent, false);
        }
    });
    


})(window.analytics === undefined ? window.analytics = {} : window.analytics, window.jQuery);